@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');


:root {
  --color-primary: #f16823;
  --color-on-primary: #FFFFFF;
  --color-background: #f5f5f5;
  --color-on-background: #282828;
  --color-product-image-background: rgba(42, 44, 41, 0.05);

  --color-primary-rgb: 241, 104, 35;
  --color-on-primary-rgb: 255, 255, 255;
  --color-background-rgb: 245, 245, 245;
  --color-on-background-rgb: 40, 40, 40;

  --color-background-brightness: 255;

  --button-corner-radius: 32px;
  --input-corner-radius: 5px;
  --image-corner-radius: 10px;

  --font-family-base: "Noto Sans", sans-serif;
  --font-family-heading: "Noto Sans", sans-serif;

  --font-weight-base: 400;
  --font-weight-base-bold: bolder;
  --font-weight-heading: 700;

  --font-style-base: normal;
  --font-style-heading: normal;

  --text-transform-base: none;
  --text-transform-heading: none;
  --logo-width: 100px;

  --image-ratio-padding: 100%;

  --letter-spacing: normal;
  --line-height-base: 1.5;
  --line-height-heading: 1.2;
  --font-size: 2.5px;
}
